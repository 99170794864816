export function cardHover(nameClass) {
    const projects = document.querySelectorAll(nameClass);
    for (const project of projects) {
        project.addEventListener('mouseenter', (e) => {
            e.currentTarget.classList.add('activeRealisationJs');
            addPausedClassFromAllProjects();
        });
        project.addEventListener('mouseleave', () => {
            removePausedClassFromAllProjects();
        });
    }

    function addPausedClassFromAllProjects() {
        for (const project of projects) {
            project.classList.add('paused');
        }
    }

    function removePausedClassFromAllProjects() {
        for (const project of projects) {
            project.classList.remove('paused', 'activeRealisationJs');
        }
    }
}
