/**
 * Fixes the menu behavior based on the window width.
 *
 * This function checks the state of a checkbox element and modifies the behavior of the menu accordingly.
 * If the window width is less than or equal to 995 pixels and the checkbox is not checked, the menu header class is removed,
 * and the body overflow property is set to 'initial' to allow scrolling.
 * If the checkbox is checked, the menu header class is added, and the body overflow property is set to 'hidden' to prevent scrolling.
 */
export function fixMenu() {
    const menuSelector = document.getElementById('checkbox_toggle');
    const headerSelector = document.querySelector('header');
    const maxWidth = window.matchMedia("(max-width: 995px)");

    if (maxWidth.matches) {
        if (!menuSelector.checked) {
            headerSelector.classList.remove('header-js');
            document.body.style.overflow = 'initial';
        } else {
            headerSelector.classList.add('header-js');
            document.body.style.overflow = 'hidden';
        }
    }
}
