/**
 * Sets up an Intersection Observer to add a CSS class to elements when they intersect the viewport.
 *
 * This function creates an Intersection Observer and defines a callback function that adds a CSS class to elements
 * when they intersect the viewport. The CSS class to be added is specified through the `addClass` parameter.
 * The Intersection Observer is set to trigger when the intersection ratio of an element reaches the specified threshold.
 * The `className` parameter is used to select the elements to observe.
 * If the `disconnect` parameter is set to true, the Intersection Observer is disconnected after observing the elements.
 *
 * @param className - The class name of the elements to observe.
 * @param addClass - The CSS class to add to the intersecting elements.
 * @param threshold - The threshold at which the Intersection Observer triggers (a number between 0 and 1).
 * @param disconnect - (Optional) Indicates whether to disconnect the Intersection Observer after observing the elements (default: false).
 */
export function setupIntersectionObserver(className, addClass, threshold, disconnect = false) {
    const observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                entry.target.classList.add(addClass);
            }
        });
    }, {threshold: threshold});

    const elements = document.getElementsByClassName(className);
    for (const element of elements) {
        observer.observe(element);
    }

    if (disconnect) {
        observer.disconnect();
    }
}

/**
 * Sets up an intersection observer to track the intersection of elements.
 *
 * @param className - The class name of the elements to observe.
 * @param otherClass - The selector for the submarine selector element.
 * @param  threshold - The percentage of intersection required to trigger the callback.
 * @param  direction - The style property and value to be applied when the observed elements intersect.
 * @param [disconnect=false] - Specifies whether to disconnect the observer after the first intersection.
 */
export function setupIntersection(className, otherClass, threshold, direction, disconnect = false) {
    const observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
            const subMarineSelector = document.querySelector('.subMarineParcours');
            const bottomInitial = subMarineSelector.style.bottom;

            if (entry.isIntersecting) {
                elementsAdd.style.opacity = '1';
                if (otherClass === `svg [id='V2']` || otherClass === `svg [id='V4']`) {
                    subMarineSelector.style.bottom = '40%';
                } else if (otherClass === `svg [id='V1']` || otherClass === `svg [id='V3']` || otherClass === `svg [id='V5']`) {
                    subMarineSelector.style.bottom = '0%';
                }
            } else {
                if (otherClass === `svg [id='V1']` || otherClass === `svg [id='V2']` || otherClass === `svg [id='V4']` || (otherClass === `svg [id='V3']` || otherClass === `svg [id='V5']`)) {
                    subMarineSelector.style.bottom = bottomInitial;
                }
            }
        });
    }, {threshold: threshold});

    const elementsAdd = document.querySelector(otherClass);
    const elements = document.querySelector(className);

    observer.observe(elements);

    if (disconnect) {
        observer.disconnect();
    }
}