import { setupIntersectionObserver } from "./event/utils";
import { fixMenu } from "./components/fixMenu";
import {cardHover} from "./components/cardHoverEffect";

document.documentElement.classList.add("js-enabled");
const navMenu = document.querySelector('nav');
const threshold = 0.01;

setupIntersectionObserver('project', 'project-observer', threshold);
window.addEventListener('resize', () => {
    setupIntersectionObserver('project', 'project-observer', threshold, true);
    setupIntersectionObserver('projects__title', 'project-observer__title', threshold);
});
cardHover('.project');

navMenu.addEventListener("click", () => {
    fixMenu();
});